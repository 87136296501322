<template>
  <v-card tile>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Matters Status List</v-toolbar-title>
      <v-spacer />
      <export_to_pdf_btn
        :labels="labels"
        :data="status"
        name="Matters List"
        auto_table
      ></export_to_pdf_btn>
      <print_button :custom_id="customId" :html_element="el()"></print_button>
    </v-toolbar>
    <v-card-text ref="stat_list">
      <v-list>
        <v-list-item v-for="(stat, index) in stats" :key="index">
          <v-list-item-content>
            <v-list-item-title>{{ stat.name }}</v-list-item-title>
            <v-list-item-subtitle>{{
              stat.description || "No description for " + stat.name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "status_list",
  components: {
    print_button: () => import("@/components/print_button"),
    export_to_pdf_btn: () => import("@/components/export_to_pdf_btn")
  },
  data: () => ({
    status: require("@/assets/status.json"),
    customId: null,
    labels: [
      {
        field: "name",
        headerName: "Status name"
      },
      {
        field: "description",
        headerName: "Status description"
      }
    ]
  }),
  beforeMount() {
    this.customId =
      "asd5as1d3asd13as1d3asd13a2" + this.getRandomColor().replace(" ", "_");
  },

  methods: {
    el() {
      let el = this.$refs.stat_list;
      return el && typeof el !== typeof undefined
        ? el
        : document.getElementById(this.customId);
    }
  },
  computed: {
    stats() {
      let stats = this.status;
      stats.sort((a, b) => {
        let forder = a.order;
        let sorder = b.order;
        if (forder === sorder) {
          return 0;
        }
        if (forder > sorder) {
          return 1;
        } else if (forder < sorder) {
          return -1;
        } else {
          return 0;
        }
      });
      return stats.filter(r => {
        return !r.ignore;
      });
    }
  }
};
</script>
